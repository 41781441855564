import { Component, OnInit } from '@angular/core';

import * as AOS from 'aos';
declare var $: any;

import { NgbCarouselConfig } from '@ng-bootstrap/ng-bootstrap'; 

@Component({
  selector: 'app-construction',
  templateUrl: './construction.component.html',
  styleUrls: ['./construction.component.css']
})
export class ConstructionComponent implements OnInit {

  constructor(config: NgbCarouselConfig) {  
    config.interval = 2000;  
    config.wrap = true;  
    config.keyboard = false;  
    config.pauseOnHover = false;  
  }  

  ngOnInit() {

    AOS.init({
      duration: 2000,
    });


    // $('#header').toggleClass('header-scrolled');

    $(window).scroll(function() {
      if ($(this).scrollTop() > 30) {
        $('#header').addClass('header-scrolled');
      } else {
        $('#header').removeClass('header-scrolled');
      }
    });

    // $("#header").css("position", "relative");

  }

}
