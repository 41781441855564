import { Component, OnInit } from '@angular/core';
import * as AOS from 'aos';
@Component({
  selector: 'app-packages',
  templateUrl: './packages.component.html',
  styleUrls: ['./packages.component.css']
})
export class PackagesComponent implements OnInit {

  active = 'top';
  activenav = 2;
  constructor() { }

  ngOnInit() {
    AOS.init({
      duration: 2000,
    });
    
  }

}
